@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Quicksand:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@400..700&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Gabriela&family=Inter:wght@100..900&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Gabriela&family=Inter:wght@100..900&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rye&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Gabriela&family=Inter:wght@100..900&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Sans+Mono:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rye&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Gabriela&family=Inter:wght@100..900&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Sans+Mono:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rye&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Gabriela&family=Inter:wght@100..900&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Sans+Mono:wght@100..900&family=PT+Sans+Caption:wght@400;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rye&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Gabriela&family=Inter:wght@100..900&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans+Mono:wght@100..900&family=PT+Sans+Caption:wght@400;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rye&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Gabriela&family=Inter:wght@100..900&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans+Mono:wght@100..900&family=Oswald:wght@200..700&family=PT+Sans+Caption:wght@400;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rye&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap'); 

@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Cedarville+Cursive&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Caveat:wght@400..700&family=Cedarville+Cursive&family=Merienda:wght@300..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Gabriela&family=Inter:wght@100..900&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans+Mono:wght@100..900&family=Oswald:wght@200..700&family=PT+Sans+Caption:wght@400;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rye&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Baskervville+SC&family=New+Amsterdam&family=Pacifico&family=Sedan:ital@0;1&family=Spicy+Rice&family=Taviraj:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&family=SUSE:wght@100..800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

.text-stroke-orange {
    -webkit-text-stroke: 2px #EA580C;
}

.text-stroke-black {
  -webkit-text-stroke: 2px #000000;
}


/* ---------------Career------------- */
.borderr{
    border: 1px solid red;
  }
  .animation-arrow-button {
    animation: changeOpacity 300ms linear forwards;
  }
  @keyframes changeOpacity {
    from {
      opacity: 0%;
      margin-left: 0px;
    }
    to {
      opacity: 100%;
      margin-left: 7.5%;
     
    }
  }
  .animation-arrow-buttonHide {
    animation: changeOpacityHide 300ms linear forwards;
    display: hidden;
  }
  @keyframes changeOpacityHide {
    from {
      margin-left: 7.5%;
    }
    to {
      margin-left: 0;
      opacity: 0;
    }
  }
  
  .scaleIn {
    animation: transformScaleIn 500ms ease-out forwards;
    transform-origin: top left;
    
  }
  @keyframes transformScaleIn {
    from {
      transform: scaleX(1) scaleY(1);
    }
    to {
      transform: scaleX(1.03) scaleY(1.05);
    }
  }
  .scaleOut {
    animation: transformScaleOut 500ms ease-out forwards;
    transform-origin: top left;
  }
  @keyframes transformScaleOut {
    from {
      transform: scaleX(1.03) scaleY(1.05);
    }
    to {
      transform: scaleX(1) scaleY(1);
    }
  }

  .bg-custom-image{
    background-image: url('./assets/images/career/sergey-zolkin-_UeY8aTI6d0-unsplash\ 1.svg');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
/* ------------------------------------------------- */

@keyframes brandNameScroll {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-50%);
  }
}

@keyframes pulse {
  100% {
      transform: scale(2.5);
      opacity: 0;
  }
}

/* -----------------------About-Us------------------------------------- */
/* Container for the image stack */
.image-stack-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: auto; /* Changed from 0 to auto to fit content */
}

/* Styling for the stacked images */
.image-stack {
  position: absolute;
  width: 100%;
  height: auto;
}

/* Keyframes for sliding effect */
@keyframes slide {
  0% {
    transform: translateX(0%);
  }
  25% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-200%);
  }
  75% {
    transform: translateX(-300%);
  }
  100% {
    transform: translateX(0%);
  }
}

/* Container for the carousel */
.carousel-container {
  overflow: hidden;
  width: 100%;
}

/* Track for the carousel images */
.carousel-track {
  display: flex;
  animation: slide 15s infinite;
}

/* Keyframes for scrolling effect */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%); /* Adjusted to match the number of items */
  }
}
/* Container for the carousel */
.carousel-container {
  overflow: hidden;
  width: 100%;
  position: relative; /* Make sure it's positioned relative to hold absolute children */
}

/* Track for the carousel images */
.carousel-track {
  display: flex;
  width: 100%;
  animation: scroll 80s linear infinite;
}

/* Keyframes for scrolling effect */
@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.carousel-container.paused .carousel-track {
  animation-play-state: paused;
}

/* In Service Pages About Us Section */

.hr-line {
  position: relative;
  height: 2px;
  background-color: gray; /* Default color */
  overflow: hidden;
}

.hr-line::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: orange; /* Hover color */
  transition: left 0.3s ease;
}

.group:hover .hr-line::before {
  left: 0;
}


/* Start for the About us Caurosel Scroller */

.carousel-track {
  display: flex;
  animation: scrollCarousel 30s linear infinite; /* Adjust the time (30s) to control the speed */
}

.carousel-container.paused .carousel-track {
  animation-play-state: paused;
}

@keyframes scrollCarousel {
  0% {
    transform: translateX(0); /* Start at the beginning */
  }
  100% {
    transform: translateX(-100%); /* Move to the end of the track */
  }
}

/* End for the About us Caurosel Scroller */

/* Hide scrollbar for WebKit browsers (Chrome, Safari) */
.scrollbar-hidden::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

/* Hide scrollbar for Firefox */
.scrollbar-hidden {
  scrollbar-width: none; /* Hide scrollbar */
}
