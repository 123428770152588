.blocks-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#blocks {
  background-color: #131313;
  width: 105vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
  z-index: 0;
}

.block {
  width: 50px;
  height: 50px;
  border: 0.5px solid rgba(255, 255, 255, 0.021);
  transition: border-color 0.3s ease;
}

.highlight {
  border-color: #EA580C;
}


.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in.visible {
  opacity: 1;
}

.fade-in.hidden {
  opacity: 0;
}
