@keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px); /* Height of the bounce */
    }
  }
  
  .animate-bounce {
    animation: bounce 0.6s infinite; /* Duration of the bounce */
  }
  
  /* Adding animation delay for each ball */
  .animation-delay-200 {
    animation-delay: 0.2s; /* Delay for the second ball */
  }
  
  .animation-delay-400 {
    animation-delay: 0.4s; /* Delay for the third ball */
  }