  /*Outer circle animation*/ 

  @keyframes spin-anti-slow {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes spin-anti-reverse {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
  
  .animate-antispin-slow {
    animation: spin-anti-slow 15s linear infinite;
  }
  
  .counter-antispin {
    animation: spin-anti-reverse 15s linear infinite;
  }
  

  /*Inner circle animation*/ 

@keyframes spin-slow {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  
  @keyframes spin-reverse {
    0% {
      transform: rotate(-360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  
  .animate-spin-slow {
    animation: spin-slow 20s linear infinite;
  }
  
  .counter-spin {
    animation: spin-reverse 20s linear infinite;
  }


